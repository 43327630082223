
@font-face {
  font-family: BrandonText-Regular;
  src: url(./fonts/BrandonText-Regular.otf);
}

@font-face {
  font-family: BrandonText-Bold;
  src: url(./fonts/BrandonText-Bold.otf);
}

@font-face {
  font-family: Real-Text-ExtraBold;
  src: url(./fonts/Real-Text-Web-W04,-ExtraBold.otf);
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: BrandonText-Regular;
  background-color: #f5f5f5;
  background-image: url("./images/pattern.png");
}

img {
  width: 100%;
  height: 100%;
  vertical-align: top;
  pointer-events: none;
}
